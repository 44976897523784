ol > li > ol > li > ol > li:before {
  width: 1.5rem !important;
  display: flex !important;
  justify-content: end !important;
  left: -2rem !important;
}

ol > li {
  list-style-type: decimal;
}

ol > li > ol > li {
  list-style-type: lower-alpha;
}

ol > li > ol > li > ol > li {
  list-style-type: lower-roman;
}
