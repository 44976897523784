.overlay {
  display: flex;
  align-items: center;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.875);
  transition: opacity 500ms;
  justify-content: center !important;
}
.popup {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  position: relative;
  transition: all 5s ease-in-out;
}
