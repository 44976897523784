ol.counter {
  counter-reset: item;
}
li.counter {
  display: block;
}

ol.counter > li.counter:before {
  content: counter(item) '. ';
  counter-increment: item;
  font-weight: bold;
}

ol.counter > li.counter > ol.counter > li.counter:before {
  content: counters(item, '.') ' ';
  font-weight: normal;
}

ol.counter > li.counter > ol.counter > li.counter:before {
  font-weight: normal;
}

div#appendix ol.counter > li.counter > ol.counter > li.counter:before {
  font-weight: bold;
}

ol.alpha {
  counter-reset: subsubitem;
}

ol.alpha > li {
  display: flex;
  margin-left: -0.5rem;
  position: relative;
}

ol.alpha > li:before {
  content: '(' counter(subsubitem, lower-alpha) ')';
  width: 1.5rem;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  left: -2rem;
  counter-increment: subsubitem;
}

ul.custom-list {
  list-style: none; /* Remove default bullets */
  padding: 0;
  margin: 0;
}
ul.custom-list li::before {
  content: '>>'; /* Add the custom marker */
  margin-right: 10px; /* Space between marker and text */
  color: #000; /* Adjust color if needed */
}
